<template>
	<div>
		<!-- 内容 -->
		<div class="orderdetailsbox">
			<div class="setcomment">
				<div class="setcommentcon">
					<div class="setservicelist clearfloat">
						<label class="setservicetext">订单评价：</label>
						<div class="setserviceright">
							<div class="setchecked">
								<template>
									<el-radio v-model="radio" label="1">开启</el-radio>
									<el-radio v-model="radio" label="2">关闭</el-radio>
								</template>
							</div>
							<div class="setip">开启后，买家可以选择默认退款原因选项（必选）</div>
						</div>
					</div>
					<div class="setservicelist clearfloat">
						<label class="setservicetext">显示评价：</label>
						<div class="setserviceright">
							<div class="setchecked">
								<template>
									<el-radio v-model="radio1" label="1">开启</el-radio>
									<el-radio v-model="radio1" label="2">关闭</el-radio>
								</template>
							</div>
							<div class="setip">开启后，买家可以选择默认退款原因选项（必选）</div>
						</div>
					</div>
					<div class="setservicelist clearfloat">
						<label class="setservicetext">评价审核：</label>
						<div class="setserviceright">
							<div class="setchecked">
								<template>
									<el-radio v-model="radio2" label="1">开启</el-radio>
									<el-radio v-model="radio2" label="2">关闭</el-radio>
								</template>
							</div>
							<div class="setip">开启后，买家可以选择默认退款原因选项（必选）</div>
						</div>
					</div>
				</div>
			</div>
			<div class="setservicelast">
				<button>保存</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				radio: '2',
				radio1: '2',
				radio2: '2',
			};
		},
		created() {},
		mounted() {},
		methods: {

		}
	};
</script>

<style scoped>
	@import url("css/order.css");
</style>

